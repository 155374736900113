<template>
  <div class="tabs">

    <!-- 生成报告按钮 -->
    <div class="generate-report-button">
      <el-button
          :class="{ 'custom-button': true, 'button-active': (isMotorConnected || isDisplayConnected) && !isMotorConnecting && !isBatteryConnecting && !isDisplayConnecting }"
          :disabled="(!isMotorConnected && !isDisplayConnected) || isMotorConnecting || isBatteryConnecting || isDisplayConnecting"
          @click="generateReportDialogVisible = true"
      >
        <el-icon style="margin-right: 5px;">
          <Document/>
        </el-icon>
        {{ $t('ProductDiagnosis.generateAreport') }}
      </el-button>
    </div>

    <el-dialog
        style="text-align: left;"
        :title="$t('ProductDiagnosis.generateReport')"
        v-model="generateReportDialogVisible"
        width="500"
        @closed="handleCloseReportDialog"
    >
      <hr class="top-separator"/>

      <!-- 弹窗内容 -->
      <el-form :model="generateReportForm" ref="generateReportForm" :rules="generateReportFormRules" label-width="150px"
               label-position="top">
        <el-row :gutter="20">

          <el-col :span="24">
            <el-form-item :label="$t('ProductDiagnosis.selectType')" prop="selectedType">
              <el-select v-model="generateReportForm.selectedType" :placeholder="$t('ProductDiagnosis.select')"
                         style="width: 100%;" multiple>
                <el-option v-for="type in types" :key="type.value" :label="type.label" :value="type.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <!-- 动态生成输入框 -->
            <el-form-item v-for="type in generateReportForm.selectedType" :key="type" :label="getLabel(type)"
                          :prop="getProp(type)">
              <el-input v-model="generateReportForm[getModelField(type)]"
                        :placeholder="getPlaceholder(type)"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductDiagnosis.email')" prop="email">
              <el-input v-model="generateReportForm.email"
                        :placeholder="$t('ProductDiagnosis.enter')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductDiagnosis.phone')" prop="phone">
              <el-input v-model="generateReportForm.phone"
                        :placeholder="$t('ProductDiagnosis.enter')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <!-- 联系人输入框 -->
            <el-form-item :label="$t('ProductDiagnosis.contactPerson')" prop="contactPerson">
              <el-input v-model="generateReportForm.contactPerson"
                        :placeholder="$t('HistoryData.CustomerFeedback.contactsPlaceholder')"></el-input>
            </el-form-item>
          </el-col>

          {{ $t('ProductDiagnosis.otherInformation') }}<br><br>

          <el-col :span="24">
            <el-form-item prop="userType">
              <label>{{ $t('ProductDiagnosis.userType') }}</label>
              <el-select class="custom-select-width" v-model="generateReportForm.userType"
                         :label="$t('ProductDiagnosis.userType')"
                         style="width: 100%;"
                         :placeholder="$t('ProductDiagnosis.select')" clearable>
                <el-option :label="$t('ProductDiagnosis.ordinaryUser')" value="1"></el-option>
                <el-option :label="$t('ProductDiagnosis.advancedUser')" value="0"></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('ProductDiagnosis.remark')" prop="otherNote">
              <el-input type="textarea" v-model="generateReportForm.otherNote"
                        :rows="3"
                        :placeholder="$t('ProductDiagnosis.enter')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            {{ $t('ProductDiagnosis.photo') }}
            <el-form-item label="" prop="fileList" style="margin-top: 7px;margin-bottom: 5px;">
              <el-upload
                  v-model:file-list="generateReportForm.fileList"
                  :limit="1"
                  :before-upload="handleBeforeUpload"
                  accept=".bin">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ProductDiagnosis.upload') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="cancelReportDialog" class="custom-reset-button">
          {{ $t('ProductManage.ModelManage.cancel') }}
        </el-button>
        <el-button class="button-background-color" @click="generateReport"> {{
            $t('ProductManage.ModelManage.confirm')
          }}
        </el-button>
      </div>
    </el-dialog>

    <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="custom-tabs">
      <el-tab-pane :label="$t('ProductDiagnosis.systemSelfCheck')" name="self-check"
                   v-if="hasPermission('diagnosis.system')"
                   :disabled="isTesting || isAnalyzing">
        <transition name="fade" mode="out-in">
          <div key="self-check" style="min-height: 500px;">
            <!-- 系统自检内容 -->
            <SystemSelfCheck :motorTypeAndModelData="motorTypeAndModelData" @update-self-check-data="handleSelfCheckData"/>
          </div>
        </transition>
      </el-tab-pane>

      <el-tab-pane :label="$t('ProductDiagnosis.dynamicTest')" name="dynamic-test" :disabled="isAnalyzing"
                   v-if="hasPermission('diagnosis.dynamic')">
        <transition name="fade" mode="out-in">
          <div key="dynamic-test" style="min-height: 500px;">
            <DynamicTest
                @start-test="isTesting = true"
                @update-dynamic-data="handleDynamicTestData"
                @cancel-test="isTesting = false"
                :motorTypeAndModelData="motorTypeAndModelData"
                @complete-test="handleCompleteTest"></DynamicTest>
          </div>
        </transition>
      </el-tab-pane>

<!--      <el-tab-pane label="动态测试的测试版" name="dynamic-test2" :disabled="isAnalyzing"
                   v-if="hasPermission('diagnosis.dynamic')">
        <transition name="fade" mode="out-in">
          <div key="dynamic-test" style="min-height: 500px;">
            <TestDynamicTest
                @start-test="isTesting = true"
                @update-dynamic-data="handleDynamicTestData"
                @cancel-test="isTesting = false"
                @complete-test="handleCompleteTest"></TestDynamicTest>
          </div>
        </transition>
      </el-tab-pane>-->

      <el-tab-pane :label="$t('ProductDiagnosis.realTimeAnalysis')" name="real-time-analysis" :disabled="isTesting"
                   v-if="hasPermission('diagnosis.realtime')">
        <div key="real-time-analysis" style="min-height: 500px;">
          <real-time-analysis :motorTypeAndModelData="motorTypeAndModelData"
                              @analysis-status="handleAnalysisStatus"></real-time-analysis>
        </div>
      </el-tab-pane>

      <!--  仅用于测试    -->
      <!--      <el-tab-pane label="用于实时分析测试">
              <div key="real-time-analysis" style="min-height: 500px;">
                <TestRealTimeAnalysis :motorTypeAndModelData="motorTypeAndModelData"
                                    @analysis-status="handleAnalysisStatus"></TestRealTimeAnalysis>
              </div>
            </el-tab-pane>-->

      <el-tab-pane :label="$t('ProductDiagnosis.historyFault')" name="history-fault"
                   v-if="hasPermission('diagnosis.record')"
                   :disabled="isTesting || isAnalyzing">
        <div key="history-fault" style="min-height: 500px;">
          <HistoryFault @update-history-fault-data="handleHistoryFaultData"></HistoryFault>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {Document, Upload} from "@element-plus/icons-vue";
import RealTimeAnalysis from "@/views/ProductDiagnosis/components/RealTimeAnalysis.vue";
//import TestRealTimeAnalysis from "@/views/ProductDiagnosis/components/TestRealTimeAnalysis.vue";
import HistoryFault from "@/views/ProductDiagnosis/components/HistoryFault.vue";
import SystemSelfCheck from "@/views/ProductDiagnosis/components/SystemSelfCheck.vue";
import {uploadFileData} from "@/api/api";
import DynamicTest from "@/views/ProductDiagnosis/components/DynamicTest.vue";
import dialogMixin from "@/mixins/dialogMixin";
//import TestDynamicTest from "@/views/ProductDiagnosis/components/TestDynamicTest.vue";

export default {
  mixins: [dialogMixin],
  components: {
    //TestDynamicTest,
    DynamicTest,
    SystemSelfCheck, HistoryFault, RealTimeAnalysis,
    //TestRealTimeAnalysis,
    Upload, Document
  },
  data() {
    return {
      selfCheckData: [], // 用于存储自检数据
      dynamicData: [], // 用于存储动态测试数据
      motorHistoryErrorData: [], // 用于存储历史故障数据

      activeTab: 'self-check', // 默认选中的选项卡
      isConnected: false, // 根据实际情况设置设备连接状态

      motorStatus: '',
      motorModel: '',
      motorSerialNumber: '',
      motorSoftwareVersion: '',
      motorHardwareVersion: '',

      batteryStatus: '',

      generateReportDialogVisible: false,
      generateReportForm: {
        selectedType: '', // 用于存储选择的类型
        brandName: '', // 假设的值，根据实际情况替换
        dealerName: '',
        oemName: '',
        userName: '',
        customerName: '',
        email: localStorage.getItem('user_email'),
        phone: '',
        vehicleInfo: '',
        DealerInfoName: '',
        DealerInfoEmail: '',
        DealerInfoPhone: '',
        userType: '',
        otherNote: '',
        contactPerson: '',
        fileList: [],
      },
      generateReportFormRules: {
        // 定义表单校验规则，如果有的话
        selectedType: [
          {required: true, message: this.$t('ProductDiagnosis.selectTypeRequired'), trigger: 'change'}
        ],
        brandName: [
          {required: true, message: this.$t('ProductDiagnosis.enterBrandName'), trigger: 'blur'}
        ],
        customerName: [
          {required: true, message: this.$t('ProductDiagnosis.enterCustomerName'), trigger: 'blur'}
        ],
        email: [
          {required: true, message: this.$t('ProductDiagnosis.enterEmail'), trigger: 'blur'},
          {type: 'email', message: this.$t('ProductDiagnosis.validEmail'), trigger: ['blur', 'change']}
        ],
        phone: [
          {required: true, message: this.$t('ProductDiagnosis.enterPhone'), trigger: 'blur'}
        ],
        DealerInfoName: [
          {required: true, message: this.$t('ProductDiagnosis.enterDealerName'), trigger: 'blur'}
        ],
        // 根据需要添加更多规则
      },
      motorInfoInterval: null, // 定时器ID，用于获取电机信息
      batteryInfoInterval: null, // 定时器ID，用于获取电池信息

      hasWebSocketListener: false, // 新增标志，检查是否已经设置过监听器

      companyLogUrl: require('@/assets/image/company-log.webp'),

      isTesting: false,
      isAnalyzing: false,
      types: [
        {value: 'brand', label: this.$t('ProductDiagnosis.brand')},
        {value: 'dealer', label: this.$t('ProductDiagnosis.dealer')},
        {value: 'oem', label: this.$t('ProductDiagnosis.oem')},
        {value: 'user', label: this.$t('ProductDiagnosis.user')}
      ],
    };
  },
  props: {
    motorInfo: Object,
    motorConfigData: Object,
    batteryInfo: Object,
    displayInfo: Object,
    dashboardInfo: Object,
    motorTypeAndModelData: Object,
    displayTypeAndModelData: Object,
    motorHistoryError: Object,
  },
  async created() {
    await this.loadSubPermissions();

  },
  mounted() {
    // 假设品牌名称存储在 localStorage 的 brandName 键下
    const brandName = localStorage.getItem('organizationName');
    if (brandName) {
      this.generateReportForm.brandName = brandName;
    }
  },
  computed: {
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },
    isOnline() {
      // 在这里计算属性值并返回
      return this.calculateIsOnline(); // 示例计算方式
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },

  },
  methods: {
    cancelReportDialog() {
      this.generateReportDialogVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.$refs.generateReportForm.resetFields();
    },
    getLabel(type) {
      switch (type) {
        case 'brand':
          return this.$t('ProductDiagnosis.brandName');
        case 'dealer':
          return this.$t('ProductDiagnosis.dealerName');
        case 'oem':
          return this.$t('ProductDiagnosis.oemName');
        case 'user':
          return this.$t('ProductDiagnosis.userName');
        default:
          return '';
      }
    },
    getProp(type) {
      switch (type) {
        case 'brand':
          return 'brandName';
        case 'dealer':
          return 'dealerName';
        case 'oem':
          return 'oemName';
        case 'user':
          return 'userName';
        default:
          return '';
      }
    },
    getModelField(type) {
      switch (type) {
        case 'brand':
          return 'brandName';
        case 'dealer':
          return 'dealerName';
        case 'oem':
          return 'oemName';
        case 'user':
          return 'userName';
        default:
          return '';
      }
    },
    getPlaceholder(type) {
      switch (type) {
        case 'brand':
          return this.$t('ProductDiagnosis.enterBrandName');
        case 'dealer':
          return this.$t('ProductDiagnosis.enterDealerName');
        case 'oem':
          return this.$t('ProductDiagnosis.enterOemName');
        case 'user':
          return this.$t('ProductDiagnosis.enterUserName');
        default:
          return '';
      }
    },
    async handleBeforeUpload(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);

        if (response.code === 0) {
          this.$message.success(this.$t('ProductManage.uploadSuccess'));
          const fileData = response;
          this.generateReportForm.fileList.push({
            name: fileData.fileName,
            key: fileData.key,
            url: fileData.url
          });
          this.generateReportForm.uploadedFile = fileData.key;
          this.generateReportForm.fileSize = fileData.fileSize;
          return true;
        } else {
          this.$message.error(this.$t('ProductManage.uploadFailed'));
          return false;
        }
      } catch (error) {
        this.$message.error(this.$t('ProductManage.uploadException'));
        return false;
      }
    },
    handleAnalysisStatus(isAnalyzing) {
      this.isAnalyzing = isAnalyzing;  // 根据实时分析的状态启用或禁用其他Tab
    },
    handleCompleteTest() {
      this.generateReportDialogVisible = true;
      this.isTesting = false;  // 更新测试状态
    },
    handleCloseReportDialog() {
      this.generateReportDialogVisible = false;
      this.resetForm();
    },
    handleSelfCheckData(data) {
      // 处理数据
      this.selfCheckData = data;
    },
    handleDynamicTestData(data) {
      // 处理数据
      this.dynamicData = data;
    },
    handleHistoryFaultData(data) {
      // 处理数据
      this.motorHistoryErrorData = data;
    },

    generateReport() {
      this.$refs.generateReportForm.validate((valid) => {
        if (valid) {

          const motorInfo = this.motorInfo;
          const motorTypeAndModelData = this.motorTypeAndModelData;
          const displayTypeAndModelData = this.displayTypeAndModelData;

          const motorConfigData = this.motorConfigData;
          const batteryInfo = this.batteryInfo;

          const displayInfo = this.displayInfo;

          const motorHistoryError = this.motorHistoryErrorData;
          const selfCheckData = this.selfCheckData;
          const dynamicData = this.dynamicData;

          const typeToKeyMap = {
            user: 'user_name',
            brand: 'brand_name',
            dealer: 'dealer_name',
            oem: 'oem_name',
          };

          // 处理生成报告的逻辑，可以使用 this.generateReportForm 来获取表单数据
          const reportData = {
            time: new Date().toLocaleString(),
            //获取客户信息
            customerInfo: {
              brandName: this.generateReportForm.brandName,
              email: this.generateReportForm.email,
              phone: this.generateReportForm.phone,
              file: this.generateReportForm.fileList,
              contactPerson: this.generateReportForm.contactPerson,

              types: this.generateReportForm.selectedType.map(type => {
                // 获取 name 字段，假设 getModelField 函数已经正确定义
                const name = this.generateReportForm[this.getModelField(type)];
                // 从 typeToKeyMap 中获取 key
                const key = typeToKeyMap[type] || 'Unknown'; // 如果 type 不在映射中，则默认为 'Unknown'
                // 返回包含 type, name, 和 key 的对象
                return {
                  type,
                  name,
                  key
                };
              })
            },
            // 获取经销商信息
            dealerInfo: {
              DealerInfoName: this.generateReportForm.DealerInfoName,
              contactPerson: this.generateReportForm.contactPerson,
              otherNote: this.generateReportForm.otherNote,
            },
            // 获取电机信息
            motorInfo: motorInfo,
            motorTypeAndModelData: motorTypeAndModelData,
            displayTypeAndModelData: displayTypeAndModelData,
            motorConfigData: motorConfigData,
            // 获取电池信息
            batteryInfo: batteryInfo,
            // 获取仪表盘信息
            displayInfo: displayInfo,
            // 获取历史故障信息
            motorHistoryError: motorHistoryError,
            // 获取系统自检信息
            selfCheckData: selfCheckData,
            // 获取动态测试信息
            dynamicData: dynamicData,
            diagnosisData: this.diagnosisData,

          };
          // 序列化并保存reportData到LocalStorage
          localStorage.setItem('reportData', JSON.stringify(reportData));

          // 使用window.open在新标签页中打开报告页面，并传递报告数据
          const routeData = this.$router.resolve({
            name: 'ReportView',
          });
          window.open(routeData.href, '_blank');
          // 生成报告完成后关闭弹窗
          this.generateReportDialogVisible = false;


        } else {
          // 表单验证未通过，可以在这里进行一些提示，如不做任何操作或显示提示信息
          return false;
        }
      });

    },

    handleTabClick(tab) {
      if ((this.isTesting && tab.name !== 'dynamic-test') || (this.isAnalyzing && tab.name !== 'real-time-analysis')) {
        this.$message({
          message: this.$t('ProductDiagnosis.taskInProgress'),
          type: 'warning',
          duration: 2000
        });
        return false;
      }
    },

    calculateIsOnline() {
      // 根据逻辑计算 isOnline 的值
      return false;
    },

  },
};
</script>

<style scoped>

/* 增加行高 */
p {
  line-height: 2.5;
}

hr {
  border: none;
  /* 移除默认边框 */
  border-top: 1px solid #f0f0f0;
  /* 添加自定义的上边框，可以根据需要调整颜色 */
}

.flex-item p {
  margin: 0;
}

.generate-report-button {
  position: absolute;
  right: 20px;
  z-index: 500;
}

.el-tabs--border-card > .el-tabs__header {
  border-bottom: 1px solid red !important; /* 更淡的边框颜色 */
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom: 1px solid red !important; /* 调整选中的选项卡下方边框的颜色 */
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

.tabs {
  margin-top: 20px;
  margin-left: 20px;
}

span {
  margin-left: 10px;
}

.analysis-button span {
  margin-left: 10px;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: rgb(0, 154, 68); /* 设置背景为白色 */
  color: white; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: white; /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: rgb(0, 154, 68); /* 悬停时背景色为白色 */
}
</style>
