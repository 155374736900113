<template>
  <el-dialog
      :title="$t('ProductDiagnosis.quickDiagnosis')"
      style="text-align: left;"
      v-model="dialogVisible"
      width="500px"
      @close="closeDialog"
  >
    <el-form ref="form" :model="form">
      <el-form-item>
        <label> {{ $t('ProductDiagnosis.majorFaultCategory') }}:</label>
        <el-select v-model="errorType" :placeholder="$t('ProductDiagnosis.pleaseSelect')" style="width: 100%"
                   @change="fetchErrorSubCategories">
          <el-option
              v-for="option in errorTypeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <label> {{ $t('ProductDiagnosis.minorFaultCategory') }}:</label>
        <el-select v-model="errorSubType" :placeholder="$t('ProductDiagnosis.pleaseSelect')" style="width: 100%"
                   @change="getSolutionLinkForSubType">
          <el-option
              v-for="option in errorSubTypeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div v-if="solutionLink" style="padding-top: 16px;">
      {{ $t('ProductDiagnosis.clickToViewInfo') }} <a :href="solutionLink" target="_blank"
                                                      style="color: rgb(0,154,68); text-decoration: none;">Click
      here</a>
    </div>
  </el-dialog>
</template>

<script>
import {getErrorTypeFirst, getErrorTypeSecond, getErrorTypeSimple} from "@/api/api";
import EventBus from "@/eventBus"; // 确保路径正确

export default {
  name: "CommonDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {},
      errorType: '',
      errorSubType: '',
      solutionLink: '',
      motor_type: '',
      errorTypeOptions: [],
      errorSubTypeOptions: [],
      dialogVisible: this.visible,
    };
  },
  watch: {
    motor_type(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchErrorCategories();
      }
    },
    visible(newVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        this.fetchErrorCategories();
      }
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
  },
  mounted() {
    EventBus.on('MotorTypeAndModelDataMessage', this.handleMotorTypeDataResponse);
  },
  beforeUnmount() {
    EventBus.off('MotorTypeAndModelDataMessage', this.handleMotorTypeDataResponse);
  },
  methods: {
    handleMotorTypeDataResponse(message) {
      this.motor_type = message.motorType === 'mid' ? 0 : message.motorType === 'hub' ? 1 : '';
    },
    closeDialog() {
      this.resetForm();
      this.dialogVisible = false;
    }
    ,
    resetForm() {
      this.errorType = '';
      this.errorSubType = '';
      this.solutionLink = '';
      this.errorTypeOptions = [];
      this.errorSubTypeOptions = [];
    }
    ,
    async fetchErrorCategories() {
      try {
        let lang = this.$store.getters.currentLanguage;

        let response = await getErrorTypeFirst({lang, motor_type: this.motor_type});

        // 如果当前语言没有返回数据，则使用默认语言 'en' 查询
        if (!(response && response.code === 0 && response.data && response.data.length > 0)) {
          lang = 'en'; // 设置为默认语言
          response = await getErrorTypeFirst({lang, motor_type: this.motor_type});
        }

        if (response && response.code === 0 && response.data) {
          this.errorTypeOptions = response.data.map((item) => ({
            label: item,
            value: item
          }));
        }
      } catch (error) {
        console.error('Failed to fetch error categories:', error);
      }
    }
    ,
    async fetchErrorSubCategories() {
      try {
        this.clearSubCategoryOptions(); // 清空子类别选项和值
        let lang = this.$store.getters.currentLanguage;
        const firstNameEncoded = encodeURIComponent(this.errorType);
        let response = await getErrorTypeSecond({lang, firstName: firstNameEncoded});

        // 如果当前语言没有返回数据，则使用默认语言 'en' 查询
        if (!(response && response.code === 0 && response.data && response.data.length > 0)) {
          lang = 'en'; // 设置为默认语言
          response = await getErrorTypeSecond({lang, firstName: firstNameEncoded});
        }

        if (response && response.code === 0 && response.data) {
          this.errorSubTypeOptions = response.data.map((item) => ({
            label: item,
            value: item
          }));
        }
      } catch (error) {
        console.error('Failed to fetch error sub categories:', error);
        this.$message.error(this.$t('ProductDiagnosis.failedToFetchErrorSubCategories'));
      }
    }
    ,
    clearSubCategoryOptions() {
      // 清空子类别选项和当前选择的值
      this.errorSubTypeOptions = [];
      this.errorSubType = '';
    }
    ,
    getSolutionLinkForSubType() {
      const firstName = this.errorType;
      const secondName = this.errorSubType;

      this.getSolutionLink(firstName, secondName);
    }
    ,
    async getSolutionLink(firstName, secondName) {
      this.solutionLink = '';
      try {
        const firstNameEncoded = encodeURIComponent(firstName); // 进行编码
        const secondNameEncoded = encodeURIComponent(secondName); // 进行编码

        let  currentLanguage = this.$i18n.locale || 'en';
        let  params = {
          [`f_${currentLanguage}-eq`]: firstNameEncoded,
          [`s_${currentLanguage}-eq`]: secondNameEncoded
        };
        let  response = await getErrorTypeSimple(params);

        // 如果当前语言没有返回数据，则使用默认语言 'en' 查询
        if (!(response && response.code === 0 && response.data && response.data.length > 0)) {
          currentLanguage = 'en'; // 设置为默认语言
          params = {
            [`f_${currentLanguage}-eq`]: firstNameEncoded,
            [`s_${currentLanguage}-eq`]: secondNameEncoded
          };
          response = await getErrorTypeSimple(params);
        }

        if (response && response.code === 0 && response.data) {
          const faq = response.data[0];
          this.solutionLink = faq.faq_url;
        } else {
          this.solutionLink = '';
        }
      } catch (error) {
        console.error('Error during get solution link:', error);
        this.$message.error(this.$t('ProductDiagnosis.cannotFetchSolutionLink'));
        this.solutionLink = '';
      }
    }
  }
}
;
</script>
